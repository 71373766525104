import { makeStyles } from "@material-ui/core";

export default function createStyle() {
  return makeStyles((theme) => ({
    cellContainer: {
      aspectRatio: "1 !important",
      width: "100% !important",
      display: "grid",
      gridTemplateAreas: "stack",
      "& > *": {
        gridColumn: "1 / -1",
        gridRow: "1 / -1"
      }
    },
    eventHandler: {
      zIndex: "1000",
      height: "100%",
      width: "100%",
      backgroundColor: "transparent"
    },
    viewerContainer: {
      width: "100%",
      aspectRatio: "1"
    },
    favIconPosition: {
      zIndex: "100",
      padding: "0.125rem",
      borderRadius: "100%",
      //backgroundColor: "rgba(255,255,255,0.2)",
      margin: "0.25rem",
      marginTop: "auto",
      marginRight: "auto",
      fontSize: "1rem"
    },
    categoryIconPosition: {
      zIndex: "100",
      fontSize: "1rem",
      padding: "0.125rem",
      borderRadius: "100%",
      margin: "0.25rem"
    },
    cellTitle: {
      flexGrow: "1",
      "&.MuiTypography-root": { textTransform: "lowercase" },
      "&.MuiTypography-root::first-letter": { textTransform: "capitalize" }
    },
    cellImage: {
      aspectRatio: "1 !important",
      width: "100% !important",
      objectFit: "cover"
    },
    productPrice: {
      zIndex: "100",
      padding: "0 0.5rem",
      margin: "0.25rem",
      marginBottom: "auto",
      marginLeft: "auto",
      fontSize: "0.8rem",
      backgroundColor: "rgba(255, 255, 255, 0.5)",
      borderRadius: "1rem"
    }
  }));
}
