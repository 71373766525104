import React, { forwardRef } from "react";
import "@google/model-viewer/dist/model-viewer";
import "focus-visible/dist/focus-visible";
import "fullscreen-polyfill/dist/fullscreen.polyfill";
import "intersection-observer/intersection-observer";
import ARButton from "./ARButton";

const ModelViewer = forwardRef((props, ref) => {
  const openAR = () => {
    ref.current.activateAR();
  };

  return (
    <model-viewer
      ref={ref}
      default-progress-bar
      // reveal={props.showPoster ? "auto" : "interaction"}
      // poster={!props.showPoster && props.posterPath}
      style={{
        backgroundColor: props.backgroundColor,
        "--poster-color": props.backgroundColor,
        display: "flex",
        width: "100%",
        height: "100%"
      }}
      src={props.model}
      ar-modes="webxr scene-viewer quick-look"
      auto-rotate
      camera-controls
      ar="true"
      ar-placement="floor,wall"
      ios-src={props["ios-src"]}
    >
      <ARButton
        style={{
          padding: "0",
          fontSize: "0.625rem",
          position: "absolute",
          bottom: "0.5rem",
          right: "0.5rem",
          borderRadius: "100%",
          aspectRatio: "1",
          minWidth: "1rem"
        }}
        slot="ar-button"
        onClick={openAR}
        iconOnly
      />
    </model-viewer>
  );
});

export default ModelViewer;
