import React, { useState, useRef } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box
} from "@material-ui/core";
import { useAnalytics } from "../../hooks/Analytics";
import { ExpandMore } from "@material-ui/icons";
//import { Icon360 } from "../Icons";
import createStyles from "./style";
import ExpandableDescription from "../ExpandableDescription";
import ProductPrice from "../ProductPrice";
import ARViewer from "../ARViewer";
import { openModelViewer } from "../../helper/utils";
import ARButton from "../ARButton";
import Carousel from "react-material-ui-carousel";
import TexturePicker from "../TexturePicker";

const ProductItem = ({
  productGroup,
  model: {
    app: { subdomain, token, sector },
    theme
  },
  ownProps: {
    title,
    modelId,
    model,
    backgroundColor,
    "ios-src": iosSrc,
    description,
    sketchfabModel
  },
  price,
  currency,
  posterPath,
  photos
}) => {
  const { track } = useAnalytics(subdomain, token);
  const [expanded, setExpanded] = useState(false);
  const [selectedModel, setSelectedModel] = useState(productGroup?.[0] || {});

  let modelRef = useRef(null);
  const useStyles = createStyles({ model });
  const classes = useStyles();

  track("event", {
    // OPTIONAL, events you want to track, by default is 'click'
    events: ["click"],
    // OPTIONAL, the prefix of the selectors, by default is 'data-amplify-analytics-'
    // in order to avoid collision with the user agent, according to https://www.w3schools.com/tags/att_global_data.asp
    // always put 'data' as the first prefix
    selectorPrefix: "data-amplify-analytics-"
  });

  const handleSetModel = (sPosterPath) => {
    setSelectedModel(
      productGroup.find(({ posterPath }) => posterPath === sPosterPath)
    );
  };

  const toggleAcordion = () => {
    setExpanded((prev) => !prev);
  };

  const onPressActiveAR = () => {
    openModelViewer(modelRef, sketchfabModel);
  };

  return (
    <Accordion style={{ background: theme.color4 }} expanded={expanded}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMore />}
        IconButtonProps={{
          onClick: toggleAcordion
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          width="100%"
          alignItems={"center"}
        >
          <Box className={classes.headerImage}>
            {selectedModel.photos && selectedModel.photos.length > 0 && (
              <img
                style={{ maxWidth: "100%", maxHeight: "100%", height: "100%", objectFit: "cover" }}
                src={selectedModel.photos[0]}
                alt={selectedModel.photos[0]}
              />
            )}
          </Box>
          <Box className={classes.textContainer}>
            <Typography
              align="left"
              color="primary"
              variant="caption"
              className={classes.mainTitle}
            >
              {selectedModel.name}
            </Typography>
            <ProductPrice
              align="left"
              variant="caption"
              price={price}
              currency={currency}
            />
          </Box>
          {(photos === null || photos === undefined) && !sketchfabModel && (
            <ARButton
              iconOnly
              onClick={onPressActiveAR}
              subdomain={subdomain}
              token={token}
              title={title}
              sector={sector}
            />
          )}
          <TexturePicker
            productGroup={productGroup}
            mode="L"
            handleChangePickTexture={handleSetModel}
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetail}>
        <Box maxWidth="100%" width="100%">
          <Box className={classes.accordionContent}>
            {(selectedModel.sketchfabModel || selectedModel.modelPath) && (
              <ARViewer
                autoplay={false}
                photos={selectedModel.photos}
                hidePlayButton={true}
                title={selectedModel.title}
                uid={selectedModel.sketchfabModel}
                model={selectedModel.modelPath}
                ref={modelRef}
                progressBarColor={"DBDBDB"}
                backgroundColor={backgroundColor}
                carousel={false}
              />
            )}
            {selectedModel.photos && selectedModel.photos.length > 0 && !(selectedModel.sketchfabModel || selectedModel.modelPath) && (
              <Carousel animation="slide" autoPlay={false}>
                {photos.map((photo, index) => (
                  <Box
                    key={index}
                    style={{ width: "100%", aspectRatio: "16/9" }}
                  >
                    <img
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                      src={photo}
                      alt={photo}
                    />
                  </Box>
                ))}
              </Carousel>
            )}
          </Box>
          <ExpandableDescription description={selectedModel.description} />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default ProductItem;
