import { makeStyles } from "@material-ui/core";

export default function createStyle() {
  return makeStyles({
    button360: {
      "& .MuiButton-label": {
        fontSize: "0.5rem",
        textDecoration: "underline"
      }
    }
  });
}