import { API, graphqlOperation } from "aws-amplify";
import { createFavs, deleteFavs } from "../graphql/mutations";

export function useFavorite() {
  const deleteFavorite = (id) => {
    return API.graphql(graphqlOperation(deleteFavs, { input: { id } })).catch(
      (err) => console.error(err)
    );
  };

  const addFavorite = (sessionID, productID) => {
    return API.graphql(
      graphqlOperation(createFavs, {
        input: { sessionID, productID }
      })
    )
      .then(
        ({
          data: {
            createFavs: { id }
          }
        }) => id
      )
      .catch((err) => console.error(err));
  };

  return { addFavorite, deleteFavorite };
}
