import React from "react";
import { Box } from "@material-ui/core";
import createStyles from "./style";

const TexturePicker = ({ productGroup, handleChangePickTexture, mode }) => {
  const useStyles = createStyles();
  const classes = useStyles();

  const getContainerClass = () => {
    if (mode === "C") return classes.cardContainer;
    if (mode === "L") return classes.listContainer;
    if (mode === "G") return classes.gridContainer;
  };

  const getItemClass = () => {
    if (mode === "C") return classes.cardItem;
    if (mode === "L") return classes.listItem;
    if (mode === "G") return classes.gridItem;
  };

  return (
    productGroup &&
    productGroup.length > 1 && (
      <Box
        className={getContainerClass(mode)}
      >
        {productGroup
          .map(({ posterPath }, index) => (
            <Box
              onClick={() => {
                handleChangePickTexture(posterPath);
              }}
              key={index}
              className={getItemClass(mode)}
              style={{ backgroundColor: posterPath }}
            ></Box>
          ))}
      </Box>
    )
  );
};

export default TexturePicker;
