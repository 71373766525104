import React from "react";

const IconPostre = () => (
  <>
    <path
      className="cls-1"
      d="M168.3,264c0-16,8-39.9,63.8-47.9,127.2-18.1,111.6-87.7,111.6-87.7s39.9,16,39.9,63.8c0,31.9-39.9,71.8-87.7,71.8h-16"
    />
    <path
      className="cls-1"
      d="M391.5,184.2S434,202.8,428,238.6c-2.5,14.9-16.2,25.4-31.3,25.4h-5.2"
    />
    <path
      className="cls-1"
      d="M120.5,160.3s.2-30.3,47.8-39.8c39.9-8,43.2-25.8,47.8-31.9"
    />
    <path
      className="cls-1"
      d="M87.1,264c-2.6-11.6-.8-24.1,4-35,6-13.3,16.3-24.3,28-33a153.9,153.9,0,0,1,31.4-17.6c56.1-24.1,135.6-26.7,153.3-97.8,0,0,39.9,15.9,39.9,47.8"
    />
    <path
      className="cls-1"
      d="M72.6,327.8l60,164.9a15.9,15.9,0,0,0,15,10.4H364.4a15.9,15.9,0,0,0,15-10.4l60-164.9-15-15a23.8,23.8,0,0,0-33.8,0l-15,15-24.9-20a23.9,23.9,0,0,0-29.9,0l-24.9,20-25-20a23.8,23.8,0,0,0-29.8,0l-25,20-24.9-20a23.9,23.9,0,0,0-29.9,0l-24.9,20-15-15a23.8,23.8,0,0,0-33.8,0Z"
    />
    <line className="cls-1" x1="216.1" y1="327.8" x2="224.1" y2="423.4" />
    <line className="cls-1" x1="136.4" y1="327.8" x2="168.3" y2="423.4" />
    <line className="cls-1" x1="295.9" y1="327.8" x2="287.9" y2="423.4" />
    <line className="cls-1" x1="375.6" y1="327.8" x2="343.7" y2="423.4" />
    <path className="cls-1" d="M256,104.5a47.8,47.8,0,1,1,45.1-63.7" />
  </>
);

export default IconPostre;
