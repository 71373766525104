import React from "react";
import "./App.css";
import HomeContainer from "./containers/HomeContainer";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";

Amplify.configure({
  ...awsconfig,
  aws_appsync_authenticationType: "API_KEY",
  Storage: {
    AWSS3: {
      bucket: "ohlivepro", //REQUIRED -  Amazon S3 bucket name
      region: "eu-west-1" //OPTIONAL -  Amazon service region
    }
  }
});

function App() {
  return (
    <div className="App">
      <HomeContainer />
    </div>
  );
}

export default App;