import React from "react";
import Icon360Path from "./Icon360";
import IconBebida from "./IconBebida";
import IconCama from "./IconCama";
import IconDucha from "./IconDucha";
import IconEntrante from "./IconEntrante";
import IconMainLogo from "./IconMainLogo";
import IconPlato from "./IconPlato";
import IconPostre from "./IconPostre";
import IconSofa from "./IconSofa";
import IconVitro from "./IconVitro";
import IconContainer from "./IconContainer";

const buildIcon = (props, Icon) => (
  <IconContainer {...props}>{Icon}</IconContainer>
);

const Cama = (props) => buildIcon(props, <IconCama />);

const Icon360 = (props) => buildIcon(props, <Icon360Path />);

const Bebida = (props) => buildIcon(props, <IconBebida />);

const Ducha = (props) => buildIcon(props, <IconDucha />);

const Entrante = (props) => buildIcon(props, <IconEntrante />);

const MainLogo = (props) => buildIcon(props, <IconMainLogo />);

const Plato = (props) => buildIcon(props, <IconPlato />);

const Postre = (props) => buildIcon(props, <IconPostre />);

const Sofa = (props) => buildIcon(props, <IconSofa />);

const Vitro = (props) => buildIcon(props, <IconVitro />);

export {
  Cama,
  Icon360,
  Bebida,
  Ducha,
  Entrante,
  MainLogo,
  Plato,
  Postre,
  Sofa,
  Vitro
};

const Icons = {
  Cama,
  Icon360,
  Bebida,
  Ducha,
  Entrante,
  MainLogo,
  Plato,
  Postre,
  Sofa,
  Vitro
};
export default Icons;
