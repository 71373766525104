import React from "react";

const IconBebidas = () => (
  <>
    <path d="M287.2,123.4h195L459.7,483.1a15.6,15.6,0,0,1-15.6,14.7H286.3a15.6,15.6,0,0,1-15.6-14.7L261.4,334" />
    <polyline points="326.2 451 388.6 92.2 490 14.2" />
    <line x1="318.4" y1="240.4" x2="474.4" y2="240.4" />
    <circle cx="412" cy="365.2" r="23.4" />
    <circle cx="380.8" cy="443.2" r="15.6" />
    <circle cx="404.2" cy="287.2" r="15.6" />
    <path d="M224.8,14.2H53.2C68.8,107.8,22,150.2,22,217S73.5,334,139,334s117-51.4,117-117S209.2,107.8,224.8,14.2Z" />
    <line x1="26.1" y1="177.7" x2="248.2" y2="178" />
    <line x1="139" y1="334" x2="139" y2="419.8" />
    <path d="M45.4,497.8H232.6s-78-31.2-93.6-78C123.4,466.6,45.4,497.8,45.4,497.8Z" />
  </>
);

export default IconBebidas;
