import React from "react";

const IconDucha = () => (
  <>
    <g id="XMLID_857_">
      <g>
        <g>
          <path
            d="M489.993,260.362h-7.656H15.312H0v15.313h15.312v20.386c0,75.578,56.928,138.102,130.154,146.964v24.053h15.313v-23.001
                    c0.86,0.015,1.719,0.033,2.583,0.033h165.853v22.969h15.312v-23.33c76.876-5.277,137.811-69.496,137.811-147.689v-20.386h7.656
                    L489.993,260.362L489.993,260.362z M334.288,428.797H163.361c-73.191,0-132.737-59.545-132.737-132.736v-20.386h436.4v20.386
                    h0.001C467.025,369.252,407.48,428.797,334.288,428.797z"
          />
          <rect x="68.905" y="290.987" width="359.839" height="15.313" />
          <path
            d="M107.077,71.896v12.936c-21.703,3.703-38.28,22.635-38.28,45.378v7.765h92.09v-7.765
                    c0-22.744-16.577-41.676-38.281-45.379V71.896c0-27.009-21.973-48.981-48.981-48.981H0v15.529h73.625
                    C92.07,38.444,107.077,53.451,107.077,71.896z M114.842,99.692c14.144,0,26.072,9.674,29.515,22.752h-59.03
                    C88.77,109.365,100.698,99.692,114.842,99.692z"
          />
          <path
            d="M237.233,176.253H436.51V69.068h15.204V53.539h-0.002H433.59c-4.544-9.069-13.924-15.313-24.739-15.313H264.89
                    c-10.816,0-20.195,6.244-24.739,15.313h-18.122v15.529h15.204V176.253z M252.761,65.883c0-6.688,5.441-12.128,12.129-12.128
                    h143.961c6.688,0,12.128,5.44,12.128,12.128v94.841H252.761V65.883z"
          />
          <rect x="237.232" y="183.802" width="15.529" height="22.969" />
          <rect x="267.857" y="183.802" width="15.529" height="22.969" />
          <rect x="298.481" y="183.802" width="15.529" height="22.969" />
          <rect x="329.106" y="183.802" width="15.529" height="22.969" />
          <rect x="359.73" y="183.802" width="15.529" height="22.969" />
          <rect x="390.355" y="183.802" width="15.529" height="22.969" />
          <rect x="420.98" y="183.802" width="15.529" height="22.969" />
          <rect x="267.965" y="130.099" width="137.811" height="15.529" />
          <rect x="76.453" y="153.177" width="15.529" height="38.281" />
          <rect x="107.077" y="153.177" width="15.529" height="61.249" />
          <rect x="137.702" y="153.177" width="15.529" height="22.969" />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </>
);

export default IconDucha;
