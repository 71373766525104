import React, { useEffect, useState } from "react";
import { AppBar, IconButton, Toolbar } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import IconMainLogoContainer from "../../containers/IconMainLogoContainer";
import createStyles from "./style";
import { getFile, listFiles } from "../../utils/loadMedia";

const HeaderBar = ({
  model,
  favoriteFilter,
  setFavoriteFilter,
  setCategory
}) => {
  const [logoSrc, setLogoSrc] = useState("");
  const useStyles = createStyles({ model });
  const classes = useStyles();

  useEffect(() => {
    const { app: { tenant: { id, customerTenantsId } = {} } = {} } = ({} =
      model);

    listFiles(`${customerTenantsId}/tenants/${id || ""}/logo/`, "public")
      .then((aFiles) => getFile(aFiles?.[0]?.key))
      .then((sLogoSrc) => {
        setLogoSrc(sLogoSrc || "");
      });
  }, [model]);

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        {logoSrc && (
          <img className={classes.logo_tenant_img} src={logoSrc} alt="" />
        )}
        <div>
          <IconMainLogoContainer
            filled={true}
            color={model?.theme?.color1}
            className={classes.logoIcon}
            viewBox="0 50 350 150"
          />
        </div>
        <div className={classes.favoriteContainer}>
          <IconButton aria-label="add to favorites" onClick={setFavoriteFilter}>
            {favoriteFilter ? (
              <FavoriteIcon color="secondary" />
            ) : (
              <FavoriteBorder color="primary" />
            )}
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export { HeaderBar };
