import { connect } from "react-redux";
import ProductCell from "../components/ProductCell/index";

const mapStateToProps = (state, ownProps) => {
  return {
    model: state,
    ownProps: ownProps
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //Acciones
    setFav: (productId, fav) =>
      dispatch({ type: "setFav", productId: productId, fav: fav })
  };
};

const createConnection = connect(mapStateToProps, mapDispatchToProps);

const ProductCellContainer = createConnection(ProductCell);

export default ProductCellContainer;
