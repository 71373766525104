import React from "react";

const IconVitro = () => (
  <>
    <g id="XMLID_613_">
      <g>
        <rect x="76.151" y="106.905" width="30.591" height="14.644" />
      </g>
    </g>
    <g id="XMLID_614_">
      <g>
        <rect x="152.628" y="106.905" width="30.591" height="14.644" />
      </g>
    </g>
    <g id="XMLID_615_">
      <g>
        <rect x="229.105" y="106.905" width="30.59" height="14.644" />
      </g>
    </g>
    <g id="XMLID_616_">
      <g>
        <rect x="305.583" y="106.905" width="30.591" height="14.644" />
      </g>
    </g>
    <g id="XMLID_618_">
      <g>
        <rect x="382.059" y="106.905" width="30.591" height="14.644" />
      </g>
    </g>
    <g id="XMLID_504_">
      <g>
        <path d="M38.238,198.677v175.246h259.371V198.677H38.238z M282.965,359.279H52.883V213.322h230.082V359.279z" />
      </g>
    </g>
    <g id="XMLID_621_">
      <g>
        <rect x="37.913" y="168.086" width="260.022" height="14.645" />
      </g>
    </g>
    <g id="XMLID_635_">
      <g>
        <rect
          x="100.793"
          y="278.975"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -155.4986 197.1877)"
          width="118.969"
          height="14.645"
        />
      </g>
    </g>
    <g id="XMLID_640_">
      <g>
        <rect
          x="190.733"
          y="288.533"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -149.3778 231.0825)"
          width="27.038"
          height="14.645"
        />
      </g>
    </g>
    <g id="XMLID_622_">
      <g>
        <path d="M313.555,168.086v205.837h144.656V168.086H313.555z M443.567,359.279H328.2V182.731h115.367V359.279z" />
      </g>
    </g>
    <g id="XMLID_643_">
      <g>
        <rect x="344.146" y="251.886" width="14.644" height="38.238" />
      </g>
    </g>
    <g id="XMLID_648_">
      <g>
        <rect x="53.208" y="53.371" width="76.477" height="14.644" />
      </g>
    </g>
    <g id="XMLID_315_">
      <g>
        <rect x="206.163" y="53.371" width="76.477" height="14.644" />
      </g>
    </g>
    <g id="XMLID_318_">
      <g>
        <rect x="359.117" y="53.371" width="76.477" height="14.644" />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M0,76.314v276.55c0,23.587,15.899,43.518,37.539,49.675v32.892h14.644v-30.916h384.968c2.174,0,4.312-0.151,6.416-0.413
                  v31.329h14.645v-35.423c18.008-8.077,30.59-26.163,30.59-47.143V76.314H0z M474.156,352.864
                  c0.001,20.405-16.6,37.005-37.005,37.005H51.65c-20.405,0-37.006-16.601-37.006-37.006V152.14h459.512V352.864z M474.157,137.495
                  H14.645V90.958h459.512V137.495z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </>
);

export default IconVitro;
