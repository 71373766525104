import React from "react";
import ProductCardContainer from "../containers/ProductCardContainer";
import ProductItemContainer from "../containers/ProductItemContainer";
import ProductCellContainer from "../containers/ProductCellContainer";
import { makeStyles, Box, Typography } from "@material-ui/core";

const ProductList = ({
  mode,
  ownProps: { favoriteFilter },
  model: {
    app: { selectedCategory },
    content: categories1,
    theme: { color3 = "", color4 = "" } = { color3: "", color4: "" }
  } = {}
}) => {
  const useStyles = makeStyles({
    productListContainer: {
      overflow: "hidden",
      flexGrow: 1,
      backgroundColor: color3
    },
    productGridContainer: {
      gap: "0.125rem",
      width: "100%",
      maxWidth: "100%",
      overflow: "hidden",
      flexGrow: 1,
      backgroundColor: color3,
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      flexWrap: "wrap"
    },
    categoryGroupTitle: {
      fontSize: "1.125rem",
      padding: "0.5rem",
      "&.MuiTypography-root": { textTransform: "lowercase" },
      "&.MuiTypography-root::first-letter": { textTransform: "capitalize" }
    },
    categoryGroupTitleGrid: {
      fontSize: "1.125rem",
      padding: "0.5rem",
      gridColumnStart: 1,
      gridColumnEnd: -1,
      "&.MuiTypography-root": { textTransform: "lowercase" },
      "&.MuiTypography-root::first-letter": { textTransform: "capitalize" }
    }
  });

  const classes = useStyles();

  const getOrder = (cat1Order = 0, cat2Order = 0) => {
    const sOrder1 = cat1Order > 9 ? String(cat1Order) : String("0" + cat1Order);
    const sOrder2 = cat2Order > 9 ? String(cat2Order) : String("0" + cat2Order);
    
    return sOrder1 + sOrder2;
  };

  const sortedProductsGroup = (aoProducts) => aoProducts.sort((a, b) => new Intl.Collator().compare(a.posterPath, b.posterPath));

  let products = categories1
    ?.flatMap(({ icon, order: cat1Order, categories2 }) => {
      //Hacemos un solo listado con los produtos
      return [
        //Productos de la categoria 2. Agregamos el id de la categoria2 para poder filtrar
        ...(categories2?.flatMap(
          ({ products, order: cat2Order, id: category2Id, name: category2Title }) =>
            products
              .filter(product => Boolean(product?.id))
              ?.map((item) => ({
                ...item,
                category2Id,
                category2Title,
                icon,
                order: getOrder(cat1Order, cat2Order)
              })) || []
        ) || [])
      ];
    })
    .filter(({ fav, category1Id, category2Id }) => {
      //Filtramos por categoria y por favoritos
      return (
        (!selectedCategory ||
          selectedCategory === category1Id ||
          selectedCategory === category2Id) &&
        (!favoriteFilter || fav)
      );
    });

  let category2Inserted = "";
  let bShowHeader = false;

  return (
    <Box
      id="ProductListContainer"
      className={
        mode === "G"
          ? classes.productGridContainer
          : classes.productListContainer
      }
    >

      {
        products
          ?.map((product, index, array) => ({...product, productGroup: Boolean(product.iosModelPath) ? array.filter(oItem => oItem.iosModelPath === product.iosModelPath) : [product]}))
          ?.filter(({ iosModelPath }, index, array) => Boolean(!iosModelPath) || index === array.findIndex(item => item.iosModelPath === iosModelPath))
          ?.sort(({ order: orderA }, { order: orderB }) => orderA - orderB)
          ?.map((product, index, array) => {
            if (category2Inserted !== product.category2Id) {
              category2Inserted = product.category2Id;
              bShowHeader = true;
            } else {
              bShowHeader = false;
            }

            return (
              <>
                {bShowHeader && (
                  <Typography
                    key={product.category2Id}
                    variant="h6"
                    color="primary"
                    align="left"
                    className={
                      mode === "G"
                        ? classes.categoryGroupTitleGrid
                        : classes.categoryGroupTitle
                    }
                  >
                    {product.category2Title}
                  </Typography>
                )}
                {mode === "C" && (
                  <ProductCardContainer
                    productGroup={sortedProductsGroup(product.productGroup)}
                    favId={product.favId}
                    categoryIcon={product.icon}
                    price={product.price}
                    currency={product.currency}
                    fav={product.fav}
                    posterPath={product.posterPath}
                    description={product.description}
                    key={product.id}
                    modelId={product.id}
                    title={product.name}
                    subtitle={product.name}
                    model={product.modelPath}
                    ios-src={product.iosModelPath}
                    backgroundColor={color4}
                    photos={product.photos}
                    sketchfabModel={product.sketchfabModel}
                  />
                )}

                {mode === "L" && (
                  <ProductItemContainer
                    productGroup={sortedProductsGroup(product.productGroup)}
                    favId={product.favId}
                    categoryIcon={product.icon}
                    price={product.price}
                    currency={product.currency}
                    fav={product.fav}
                    posterPath={product.posterPath}
                    description={product.description}
                    key={product.id}
                    modelId={product.id}
                    title={product.name}
                    subtitle={product.name}
                    model={product.modelPath}
                    ios-src={product.iosModelPath}
                    backgroundColor={color4}
                    photos={product.photos}
                    sketchfabModel={product.sketchfabModel}
                  />
                )}

                {mode === "G" && (
                  <ProductCellContainer
                    productGroup={sortedProductsGroup(product.productGroup)}
                    favId={product.favId}
                    categoryIcon={product.icon}
                    price={product.price}
                    currency={product.currency}
                    fav={product.fav}
                    posterPath={product.posterPath}
                    description={product.description}
                    key={product.id}
                    modelId={product.id}
                    title={product.name}
                    subtitle={product.name}
                    model={product.modelPath}
                    ios-src={product.iosModelPath}
                    backgroundColor={color4}
                    photos={product.photos}
                    sketchfabModel={product.sketchfabModel}
                  />
                )}
              </>
            );
          })
      }
    </Box>
  );
};

export default ProductList;
