import React from "react";

const IconCama = () => (
  <>
    <g id="XMLID_641_">
      <g>
        <g>
          <path
            d="M444.058,233.57v-82.247c0-49.667-40.407-90.073-90.073-90.073H136.01c-49.667,0-90.073,40.407-90.073,90.073v82.247
                    C19.214,242.976,0,268.458,0,298.357v15.776c0,26.958,15.621,50.327,38.281,61.56v53.05h15.313V381.12
                    c4.856,1.093,9.898,1.689,15.08,1.689H421.32c5.182,0,10.224-0.596,15.08-1.689v47.625h15.313v-53.05
                    c22.66-11.233,38.281-34.602,38.281-61.56v-15.776C489.994,268.458,470.781,242.976,444.058,233.57z M61.25,151.323
                    c0-41.224,33.537-74.762,74.76-74.762h217.974c41.224,0,74.761,33.538,74.761,74.761v60.829
                    c-8.096-9.14-18.795-15.719-30.625-18.804v-22.928c0-17.98-14.628-32.608-32.608-32.608h-72.594
                    c-17.981,0-32.608,14.628-32.608,32.608v20.958h-30.624v-20.958c0-17.98-14.628-32.608-32.609-32.608h-72.594
                    c-17.98,0-32.608,14.628-32.608,32.608v22.929c-11.829,3.085-22.528,9.664-30.625,18.804V151.323z M382.808,206.689
                    c16.427,0,31.678,8.93,39.82,23.028c-0.437-0.008-0.869-0.033-1.308-0.033H68.673c-0.438,0-0.871,0.025-1.308,0.033
                    c8.143-14.097,23.396-23.028,39.822-23.028H382.808z M107.187,191.377v-20.958c0-9.537,7.759-17.296,17.296-17.296h72.594
                    c9.537,0,17.296,7.759,17.296,17.296v20.958H107.187z M382.808,191.377H275.622v-20.958c0-9.537,7.759-17.296,17.296-17.296
                    h72.594c9.537,0,17.296,7.759,17.296,17.296V191.377z M421.321,367.495H68.673c-29.423,0-53.361-23.938-53.361-53.361v-15.776
                    c0-29.424,23.938-53.361,53.361-53.361H421.32c29.424,0,53.361,23.938,53.361,53.361v15.776h0.001
                    C474.682,343.557,450.744,367.495,421.321,367.495z"
          />
          <rect x="53.593" y="329.214" width="382.808" height="15.313" />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </>
);

export default IconCama;
