import { makeStyles } from "@material-ui/core";

export default function createStyle() {
  return makeStyles({
    main: {
      height: "100%",
      width: "100%",
      objectFit: "cover"
    }
  });
}
