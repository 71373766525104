import { Storage } from "aws-amplify";

export function getFile(path, level) {
  try {
    return Storage.get(path, {
      level,
      expires: 3600,
      download: false
    });
  } catch (error) {
    throw new Error(error);
  }
}

export function listFiles(path, level) {
  try {
    return Storage.list(path, {
      level,
      expires: 3600
    });
  } catch (error) {
    throw new Error(error);
  }
}

export async function uploadFile(file, path, parameters = {}) {
  try {
    return Storage.put(path, file, parameters);
  } catch (error) {
    throw new Error(error);
  }
}

export async function removeFile(path, parameters = {}) {
  try {
    return Storage.remove(path, parameters);
  } catch (error) {
    throw new Error(error);
  }
}
