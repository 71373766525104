import React from "react";

const IconPlato = () => (
  <>
    <line className="cls-1" x1="247.9" y1="158.8" x2="247.9" y2="264.1" />
    <line className="cls-1" x1="215.5" y1="264.1" x2="215.5" y2="158.8" />
    <path className="cls-1" d="M207.4,337c-14.6-8.8-24.3-23.4-24.3-40.5V175" />
    <path
      className="cls-1"
      d="M280.3,175V296.5c0,17.1-9.7,31.7-24.3,40.5V507.1"
    />
    <line className="cls-1" x1="207.4" y1="337" x2="207.4" y2="507.1" />
    <line className="cls-1" x1="207.4" y1="337" x2="223.6" y2="345.1" />
    <path
      className="cls-1"
      d="M102.1,385.6,77.8,353.2V247.9c0-48.6,16.2-89.1,56.7-89.1V507.1"
    />
    <path className="cls-1" d="M183.1,97.8A174.2,174.2,0,1,1,320.8,417.7" />
    <path
      className="cls-1"
      d="M94,377.5v70.4A250.8,250.8,0,0,1,4.9,256C4.9,117.3,117.3,4.9,256,4.9S507.1,117.3,507.1,256,394.7,507.1,256,507.1"
    />
  </>
);

export default IconPlato;
