import React from "react";
import {
  Entrante,
  Plato,
  Postre,
  Bebida,
  Sofa,
  Vitro,
  Cama,
  Ducha
} from "../Icons";

const CategoryIcon = ({ category, ...props }) => {
  const icons = {
    entrantes: <Entrante {...props} />,
    principales: <Plato {...props} />,
    postres: <Postre {...props} />,
    otros: <Bebida {...props} />,
    salon: <Sofa {...props} />,
    cocina: <Vitro {...props} />,
    badroom: <Ducha {...props} />,
    dormitorio: <Cama {...props} />
  };

  return icons[category];
};

export default CategoryIcon;
