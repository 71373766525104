import React, { useState, useRef } from "react";
import { Typography, Box } from "@material-ui/core";
import { useAnalytics } from "../../hooks/Analytics";
import createStyles from "./style";
import ItemDialog from "../ItemDialog";
import { useFavorite } from "../../hooks/useFavorite";
import FavoriteIcon from "../FavIcon";
import CategoryIcon from "../CategoryIcon";
import ProductPrice from "../ProductPrice";
import ARViewer from "../ARViewer";
import TexturePicker from "../TexturePicker";

const ProductCell = ({
  productGroup,
  model: {
    app: { subdomain, token },
    theme
  },
  ownProps: {
    categoryIcon,
    title,
    modelId,
    model,
    backgroundColor,
    "ios-src": iosSrc,
    fav,
    favId,
    description,
    sketchfabModel
  },
  setFav,
  price,
  currency,
  posterPath,
  photos
}) => {
  const [open, setOpen] = useState(false);
  const useStyles = createStyles();
  const classes = useStyles();
  const { addFavorite, deleteFavorite } = useFavorite();
  const [selectedModel, setSelectedModel] = useState(productGroup?.[0] || {});
  let modelRef = useRef(null);

  const onSetFavorite = () => {
    if (fav) {
      deleteFavorite(favId).then(() => {
        setFav(modelId, false, favId);
      });
    } else {
      addFavorite(localStorage.getItem("sessionId"), modelId).then((favId) => {
        setFav(modelId, true, favId);
      });
    }
  };

  const handleSetModel = (sPosterPath) => {
    setSelectedModel(
      productGroup.find(({ posterPath }) => posterPath === sPosterPath)
    );
  };

  const { track } = useAnalytics(subdomain, token);

  track("event", {
    // OPTIONAL, events you want to track, by default is 'click'
    events: ["click"],
    // OPTIONAL, the prefix of the selectors, by default is 'data-amplify-analytics-'
    // in order to avoid collision with the user agent, according to https://www.w3schools.com/tags/att_global_data.asp
    // always put 'data' as the first prefix
    selectorPrefix: "data-amplify-analytics-"
  });

  return (
    <Box
      style={{ background: theme.color4 }}
      display="flex"
      flexDirection="column"
    >
      <Box className={classes.cellContainer}>
        {/*<Box {...longPressEvent} className={classes.eventHandler} />*/}
        <Box className={classes.viewerContainer}>
          {(sketchfabModel || selectedModel.modelPath) && (
            <ARViewer
              photos={selectedModel.photos}
              title={selectedModel.title}
              uid={selectedModel.sketchfabModel}
              model={selectedModel.modelPath}
              hidePlayButton={true}
              ref={modelRef}
              progressBarColor={"DBDBDB"}
              backgroundColor={backgroundColor}
              iconSize="4rem"
            />
          )}
          {/* {photos && photos.length > 0 && !(sketchfabModel || model) && (
            <img style={{ maxHeight: "100%" }} src={photos[0]} />
          )} */}
        </Box>
        <FavoriteIcon
          onClick={onSetFavorite}
          favorite={fav}
          color="primary"
          className={classes.favIconPosition}
        />
        <CategoryIcon
          category={categoryIcon}
          color={theme.color1}
          className={classes.categoryIconPosition}
          viewBox="0 0 512 512"
        />
        <ProductPrice
          color="primary"
          className={classes.productPrice}
          price={price}
          currency={currency}
        />
      </Box>
      <Typography
        variant="caption"
        align="center"
        className={classes.cellTitle}
        color="Primary"
      >
        {selectedModel.name}
      </Typography>
      <TexturePicker
        mode="G"
        productGroup={productGroup}
        handleChangePickTexture={handleSetModel}
      />
      {/*<Typography sx={{ color: "text.secondary" }}>
        {formatProductPrice(price, currency)}
      </Typography>
       */}
      <ItemDialog
        uid={sketchfabModel}
        open={open}
        setOpen={setOpen}
        title={title}
        id={modelId}
        posterPath={posterPath}
        model={model}
        ios-src={iosSrc}
        backgroundColor={backgroundColor}
        description={description}
        fav={fav}
        price={price}
        currency={currency}
        photos={photos}
      />
    </Box>
  );
};

export default ProductCell;
