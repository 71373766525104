import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//Redux
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { generalReducer } from './reducers';
import loadInitalData from './model/initialLoad';
import ThunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

const composeEnhancer = composeWithDevTools(applyMiddleware(ThunkMiddleware));

const store = createStore(generalReducer, composeEnhancer);

store.dispatch(loadInitalData);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
