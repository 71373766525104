import { React, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import FilterCard from "./FilterCard";

const FilterList = (props) => {
  const useStyles = makeStyles({
    filterListContainer: {
      padding: "0 2rem",
      boxSizing: "border-box",
      width: "100%",
      height: "2rem",
      textAlign: "left"
    }
  });

  const classes = useStyles();

  const [filters, setFilters] = useState([]);

  const setAllProductsFilterName = () => {
    var sector =
      props.model.app.sector !== null && props.model.app.sector !== undefined
        ? props.model.app.sector
        : 9999;
    var linkName;

    switch (sector) {
      case 9999:
        linkName = "TODOS LOS PRODUCTOS";
        break;
      
      case 9998:
        linkName = "TODOS LOS MODELOS";
        break;

      case 1: //Restaurantes
        linkName = "TODOS LOS PLATOS";
        break;

      case 2: //Muebles
        linkName = "TODOS LOS MUEBLES";
        break;

      default:
        break;
    }

    return linkName;
  };

  //Cada ved que cambie la propiedad de categoría seleccionada
  useEffect(() => {
    var categories1 = props.model.content;

    var aFiltersLabels = [];

    if (props.model.app.selectedCategory !== "") {
      categories1.forEach((category1) => {
        if (category1.id === props.model.app.selectedCategory) {
          aFiltersLabels.push({ id: category1.id, name: category1.name });
        } else {
          category1.categories2.forEach((category2) => {
            if (category2.id === props.model.app.selectedCategory) {
              aFiltersLabels.push({ id: category2.id, name: category2.name });
            }
          });
        }
      });
    } else {
      aFiltersLabels.push({ id: "all", name: setAllProductsFilterName() });
    }

    if (props.model.app.favoriteFilter) {
      aFiltersLabels.push({ id: "favs", name: "FAVORITOS" });
    }

    var aFilters = [];

    aFiltersLabels.forEach((elem) => {
      if (elem.id === "all") {
        aFilters.push([
          <FilterCard
            key={elem.id}
            color="primary"
            className={classes.filterCard}
            label={elem.name}
          />
        ]);
      } else if (elem.id !== "favs") {
        aFilters.push([
          <FilterCard
            key={elem.id}
            color="primary"
            className={classes.filterCard}
            onDelete={() => {
              props.setCategory("");
            }}
            label={elem.name}
          />
        ]);
      } else {
        aFilters.push([
          <FilterCard
            key={elem.id}
            color="primary"
            className={classes.filterCard}
            onDelete={() => {
              props.setFavFilter(false);
            }}
            label={elem.name}
          />
        ]);
      }
    });

    setFilters(aFilters);
  }, [props.model.app.selectedCategory, props.model.app.favoriteFilter, classes.filterCard]);

  return <div className={classes.filterListContainer}>{filters}</div>;
};

export default FilterList;
