import React from "react";

const IconEntrantes = () => (
  <>
    <path d="M5.7,207.5c0,107,64.6,210,161.5,234.1V474a8,8,0,0,0,8,8.1H336.8a8,8,0,0,0,8-8.1V441.6c96.9-24.1,161.5-127.1,161.5-234.1Z" />
    <line className="cls-1" x1="256" y1="441.7" x2="344.8" y2="441.7" />
    <path className="cls-1" d="M62.2,175.2a80.8,80.8,0,0,1,161.5,0" />
    <line className="cls-1" x1="142.9" y1="134.9" x2="142.9" y2="142.9" />
    <line className="cls-1" x1="186.2" y1="152.2" x2="180.5" y2="157.9" />
    <line className="cls-1" x1="99.7" y1="152.2" x2="105.4" y2="157.9" />
    <path
      className="cls-1"
      d="M348.6,78.3c51.8-17.4,109.3-16.1,109.3-16.1s6.1,59.5-8.1,113"
    />
    <path
      className="cls-1"
      d="M175.2,54.1V29.9s121.2,0,169.6,48.4c33.7,33.7,35,71.5,24.3,96.9"
    />
    <line className="cls-1" x1="239.8" y1="102.6" x2="312.5" y2="175.2" />
    <line className="cls-1" x1="288.3" y1="102.6" x2="288.3" y2="151" />
  </>
);

export default IconEntrantes;
