import React from "react";

const Icon360 = () => (
  <>
    <g>
      <g>
        <path
          d="M462.4,231c0-49.6-46-93.2-114.4-116.4c-4-11.2-8-22-13.2-32C309.6,30.2,271.6,0.2,231.2,0.2C156.8,0.2,96,103.8,96,231
                c0,35.6,4.8,70,13.6,101.2c-58-22-96.4-59.2-96.4-101.2c0-37.2,30-72,82.4-95.2c3.2-1.6,4.8-5.2,3.2-8.8c-1.6-3.2-5.6-4.8-8.8-3.2
                C33.2,149.4,0,188.6,0,231c0,49.6,46,93.2,114.4,116.8c5.6,16.4,12,31.6,20,45.2c25.6,44.4,60,69.2,96.8,69.2
                c42.8,0,82-33.2,107.6-90.8c1.6-3.2,0-7.2-3.2-8.8c-3.2-1.6-7.2,0-8.8,3.2c-23.6,52.8-58.4,83.2-95.6,83.2c-32,0-62-22-85.2-62.4
                c-6-10.4-11.2-21.6-16-34c30.4,8.8,64.8,13.6,101.2,13.6C358.4,366.2,462.4,305.4,462.4,231z M124.8,337.4
                c-10.4-32-15.6-68.4-15.6-106.4c0-120,54.8-218,122-218c35.2,0,68.8,27.6,92,75.2c3.2,6.8,6,13.6,8.8,21.2
                c-30.4-8.8-64.8-13.6-100.8-13.6c-31.6,0-62.4,3.6-90.8,10.8c-3.6,0.8-5.6,4.4-4.8,8c0.8,3.6,4.4,5.6,8,4.8
                c27.6-6.8,57.2-10.4,87.6-10.4c38.4,0,74.8,5.6,106.4,15.6c2.8,9.2,5.6,18.8,7.6,28.4c5.6,24.8,8,51.2,8,78
                c0,30.4-3.6,59.6-10.4,86.8c-0.8,3.6,1.2,7.2,4.8,8s7.2-1.2,8-4.8c7.2-28.4,10.4-58.8,10.4-90c0-28-2.8-54.8-8.4-80.8
                c-1.6-6.8-3.2-13.6-5.2-20.4c58,22,96,59.2,96,101.2c0.8,67.2-97.2,122-217.2,122C192.8,353,156.4,347.4,124.8,337.4z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M165.2,242.6c8.8-3.2,13.2-9.2,13.2-16.8c0-9.6-8.4-17.6-23.6-17.6c-9.2,0-17.6,2.8-22,5.2l3.6,12
                c2.8-1.6,9.2-4.4,14.8-4.4c7.2,0,10.4,3.2,10.4,7.6c0,6-7.2,8-12.8,8.4h-6.4v12h6.8c7.2,0,14.4,3.2,14.4,10.4
                c0,5.2-4.4,9.6-13.2,9.6c-6.8,0-13.6-2.8-16.4-4.4l-3.6,12.4c4,2.8,12,5.2,21.2,5.2c18,0,29.2-9.2,29.2-22
                C180.8,250.6,173.6,244.2,165.2,242.6z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M222.8,232.6c-6.8,0-11.6,2-15.2,5.6h-0.4c2-8.4,8.4-16,23.2-17.2c2.8-0.4,4.8-0.4,6.4-0.4v-12.8c-1.6,0-3.6,0-6.8,0
                c-11.2,0.4-20.4,4-27.6,10.4c-7.6,7.2-12.8,18.4-12.8,31.6c0,17.2,9.2,31.2,28.4,31.2c15.6,0,26.4-11.6,26.4-26
                C244.8,241,234.8,232.6,222.8,232.6z M218.4,269.4c-7.6,0-11.2-6.8-11.6-14.4c0-2,0.4-3.2,0.8-4c1.6-3.6,5.2-6.4,10-6.4
                c7.2,0,10.8,5.6,10.8,12.4C228,264.2,224.4,269.4,218.4,269.4z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M278.8,207.8c-18.4,0-27.2,16.4-27.2,37.2c0,20.4,8.4,36.8,26.8,36.8c18.4,0,27.2-15.2,27.2-37.2
                C305.2,225,297.6,207.8,278.8,207.8z M278.4,269c-6.4,0-10.4-8-10.4-24c0-16.4,4-24.4,10.4-24.4c6.8,0,10,8.4,10,24
                C288.4,261,285.2,269,278.4,269z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M321.6,165.8c-13.2,0-24,10.8-24,24s10.8,24,24,24s24-10.8,24-24C346,176.6,334.8,165.8,321.6,165.8z M321.6,201
                c-6,0-10.8-4.8-10.8-10.8s4.8-10.8,10.8-10.8s10.8,4.8,10.8,10.8C332.8,196.2,327.6,201,321.6,201z"
        />
      </g>
    </g>
  </>
);

export default Icon360;
