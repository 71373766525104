import React, { useCallback } from "react";
import { Typography } from "@material-ui/core";

const ProductPrice = ({ price = 0, currency, ...props }) => {
  const formatPrice = useCallback((price, currency) => {

    return new Intl.NumberFormat("es-ES", {
      style: "currency",
      currency: currency ?? "EUR",
      currencyDisplay: "symbol"
    }).format(price);
  }, []);

  return !!price ? (
    <Typography {...props}>{formatPrice(price, currency)}</Typography>
  ) : (
    ""
  );
};

export default ProductPrice;
