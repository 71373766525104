import { connect } from "react-redux";
import ProductCard from "../components/ProductCard/index";

const mapStateToProps = (state, ownProps) => {
  return {
    model: state,
    ownProps: ownProps
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //Acciones
    setFav: (productId, fav, favId) =>
      dispatch({ type: "setFav", productId , fav, favId })
  };
};

const createConnection = connect(mapStateToProps, mapDispatchToProps);

const ProductCardContainer = createConnection(ProductCard);

export default ProductCardContainer;
