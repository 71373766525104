export const listSessions = /* GraphQL */ `
  query ListSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        favorites {
          items {
            id
            productID
            sessionID
          }
        }
        id
        ip
        token
      }
    }
  }
`;

export const listFavs = /* GraphQL */ `
  query ListFavs(
    $filter: ModelFavsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productID
        sessionID
      }
    }
  }
`;

export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      name
      customerID
      theme {
        color1
        color2
        color3
        color4
        id
      }
      categories1Categories2 {
        items {
          category1ID
          category2ID
          id
          tenantID
        }
      }
      categories2products {
        items {
          category2ID
          id
          productID
          tenantID
        }
      }
    }
  }
`;

export const getCategory1 = /* GraphQL */ `
  query GetCategory1($id: ID!) {
    getCategory1(id: $id) {
      id
      order
      name
      tooltip
      icon
      Final
      categories2 {
        items {
          id
          order
          name
          tooltip
        }
        nextToken
      }
      products {
        items {
          id
          name
          description
          photos
          sketchfabModel
          iosModelPath
          modelPath
          posterPath
        }
        nextToken
      }
    }
  }
`;

export const getCategory2 = /* GraphQL */ `
  query GetCategory2($id: ID!) {
    getCategory2(id: $id) {
      id
      order
      name
      tooltip
      products {
        items {
          id
          name
          description
          modelPath
          photos
          sketchfabModel
          iosModelPath
          posterPath
        }
        nextToken
      }
    }
  }
`;

export const listCategory1Category2s = /* GraphQL */ `
  query listCategory1Category2s($filter: ModelCategory1Category2FilterInput) {
    listCategory1Category2s(filter: $filter) {
      items {
        category1ID
        category2ID
        id
        tenantID
        category1 {
          id
          name
          icon
          order
        }
        category2 {
          id
          name
          order
        }
      }
    }
  }
`;

export const listCategory2Products = /* GraphQL */ `
  query listCategory2Products(
    $filter: ModelCategory2ProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategory2Products(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        category2ID
        id
        tenantID
        productID
        category2 {
          id
          name
        }
        product {
          currency
          description
          id
          iosModelPath
          name
          price
          sketchfabModel
          iosModelPath
          modelPath
          posterPath
        }
      }
      nextToken
    }
  }
`;

const queries = {
  listSessions,
  listFavs,
  getTenant,
  getCategory1,
  getCategory2,
  listCategory1Category2s,
  listCategory2Products
};

export default queries;
