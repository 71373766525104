import { makeStyles } from "@material-ui/core";

export default function createStyle({ color3 }) {
  return makeStyles((theme) => ({
    root: {
      background: color3,
      [theme.breakpoints.up("lg")]: {
        padding: "0 30%"
      }
    }
  }));
}
