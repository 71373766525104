import { connect } from "react-redux";
import AppContent from "../components/AppContent/index.js";

const mapStateToProps = (state, ownProps) => {
  return {
    model: state,
    ownProps: ownProps
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //Acciones
    setContent: (content) => dispatch({ type: "setContent", text: content }),
    setCategory: (category) =>
      dispatch({ type: "setSelectedCategory", text: category })
  };
};

const createConnection = connect(mapStateToProps, mapDispatchToProps);

const AppContainer = createConnection(AppContent);

export default AppContainer;
