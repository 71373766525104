import { connect } from "react-redux";
import BottomBar from "../components/BottomBar";

const mapStateToProps = (state) => {
  return {
    model: state
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //Acciones
    setCategory: (category) =>
      dispatch({ type: "setSelectedCategory", text: category })
  };
};

const createConnection = connect(mapStateToProps, mapDispatchToProps);

const BottomBarContainer = createConnection(BottomBar);

export default BottomBarContainer;
