import { makeStyles } from "@material-ui/core";

export default function createStyle({ model }) {
  return makeStyles({
    accordionSummary: {
      paddingLeft: "0",
      "& .MuiAccordionSummary-content": {
        margin: "0"
      }
    },
    textContainer: {
      padding: "0 0.5rem",
      flexGrow: "1",
      display: "flex",
      width: "100%",
      flexDirection: "column",
      "@media (min-width: 500px)": {
        flexDirection: "row"
      }
    },
    headerImage: {
      height: "6rem",
      minWidth: "6rem",
      maxWidth: "6rem",
      width: "6rem",
      objectFit: "cover"
    },
    accordionDetail: {
      padding: 0
    },
    accordionContent: {
      padding: 0,
      aspectRatio: "16/9"
    },
    mainTitle: {
      flexGrow: "1",
      "&.MuiTypography-root": { textTransform: "lowercase" },
      "&.MuiTypography-root::first-letter": { textTransform: "capitalize" }
    }
  });
}
