import { API, graphqlOperation } from "aws-amplify";
import mutations from "./mutations";
import queries from "./queries";

const callOperation = async (operation, operationDetails, action) => {
  return API.graphql(graphqlOperation(operation, operationDetails)).catch(
    (e) => {
      console.error(e);
      throw new Error(`Service ${action} failed`);
    }
  );
};

const callQuery = async (query, operationDetails) => {
  return callOperation(queries[query], operationDetails, query);
};

const callListQuery = async (query, operationDetails) => {
  const response = await callQuery(query, operationDetails, query);
  const nextToken = response?.data?.[query]?.nextToken;
  const data = response?.data?.[query]?.items || [];
  let nextTokenResponse = [];

  if (nextToken) {
    nextTokenResponse = await callListQuery(query, { ...operationDetails, nextToken }) || [];
  }
  
  return [
    ...data,
    ...nextTokenResponse
  ];
};

const callGetQuery = async (query, operationDetails) => {
  return callQuery(query, operationDetails, query).then(
    (response) => response?.data?.[query] || {}
  );
};

const callMutation = async (mutation, operationDetails) => {
  return callOperation(mutations[mutation], operationDetails, mutation).then(
    (response) => ({
      ...(response?.data?.[mutation] || {})
    })
  );
};

export { callOperation, callQuery, callListQuery, callMutation, callGetQuery };
