import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import BottomBarContainer from "../../containers/BottomBarContainer";
import AppContainer from "../../containers/AppContainer";
import { ThemeProvider } from "@material-ui/core/styles";
import themeGenerator from "../../Theme";
import { useAnalytics } from "../../hooks/Analytics";
import { HeaderBar } from "../HeaderBar";
import createStyles from "./style";

const Home = ({ model, setFavFilter, setCategory }) => {
  const { track } = useAnalytics(model?.app?.subdomain, model?.app?.token);
  track("session");

  const theme = themeGenerator(model.theme || {});

  const [favoriteFilter, setFavoriteFilter] = useState(false);
  const [favButtonPush, setFavButtonPush] = useState(false);

  const useStyles = createStyles({ color3: model?.theme?.color3 });
  const classes = useStyles();

  const handleFavoriteFilter = () => {
    setFavoriteFilter(!favoriteFilter);
    setFavButtonPush(true);
    setFavFilter(!favoriteFilter);
  };

  useEffect(() => {
    setFavoriteFilter(model.app.favoriteFilter);
    setFavButtonPush(false);
  }, [model.app.favoriteFilter]);

  return model.app.loading ? (
    <div></div>
  ) : (
    <ThemeProvider theme={theme}>
      <Box
        className={classes.root}
        display="flex"
        flexDirection="column"
        height="100%"
      >
        <HeaderBar
          model={model}
          setCategory={setCategory}
          favoriteFilter={favoriteFilter}
          setFavoriteFilter={handleFavoriteFilter}
        />
        <AppContainer
          favoriteButtonPush={favButtonPush}
          setFavButtonPush={setFavButtonPush}
          favoriteFilter={favoriteFilter}
          setFavoriteFilter={setFavoriteFilter}
        />
        <BottomBarContainer />
      </Box>
    </ThemeProvider>
  );
};

export default Home;
