import React from "react";

const IconSofa = () => (
  <>
    <g id="XMLID_704_">
      <g>
        <g>
          <rect x="84.218" y="157.344" width="15.313" height="14.526" />
          <rect x="382.808" y="157.344" width="15.313" height="14.526" />
          <rect x="298.59" y="157.344" width="15.313" height="14.526" />
          <rect x="176.092" y="157.344" width="15.313" height="14.526" />
          <path
            d="M466.849,291.636c13.601-5.878,23.145-19.421,23.145-35.155c0-15.665-9.463-29.155-22.969-35.075V129.97
                    c0-27.338-22.241-49.58-49.58-49.58H286.921c-17.631,0-33.132,9.258-41.924,23.161c-8.792-13.902-24.293-23.161-41.924-23.161
                    H72.548c-27.338,0-49.58,22.242-49.58,49.58v91.437C9.463,227.326,0,240.816,0,256.481c0,15.734,9.544,29.276,23.145,35.155
                    C9.429,298.586,0,312.816,0,329.215c0,23.219,18.89,42.109,42.109,42.109h3.828v38.281H61.25v-38.281h367.495v38.281h15.313
                    v-38.281h3.828c23.219,0,42.109-18.89,42.109-42.109C489.994,312.816,480.565,298.586,466.849,291.636z M474.682,256.481
                    c0,12.665-10.304,22.968-22.969,22.968c-12.664,0-22.968-10.304-22.968-22.968s10.304-22.968,22.968-22.968
                    C464.378,233.513,474.682,243.817,474.682,256.481z M428.771,287.106h-3.262c0.32-0.637,0.613-1.287,0.882-1.951
                    C427.159,285.834,427.95,286.489,428.771,287.106z M252.655,260.495V129.97c-0.002-18.896,15.37-34.268,34.266-34.268h130.524
                    c18.896,0,34.268,15.372,34.268,34.268v88.231c-11.05,0-21.015,4.712-28.009,12.225c-4.852-7.136-13.036-11.832-22.296-11.832
                    h-67.823c-14.857,0-26.945,12.087-26.945,26.944v29.543c0,4.32,1.028,8.403,2.843,12.025h-56.828V260.495z M413.825,261.916
                    v13.164c0,5.796-3.996,10.663-9.374,12.025H330.54c-5.378-1.362-9.374-6.229-9.374-12.024v-29.543
                    c0-6.847,5.57-12.418,12.418-12.418h67.823c6.848,0,12.418,5.571,12.418,12.418v5.508c-0.254,1.777-0.393,3.59-0.393,5.435
                    C413.432,258.327,413.571,260.14,413.825,261.916z M38.281,129.97c0-18.896,15.372-34.268,34.267-34.268h130.525
                    c18.895,0,34.268,15.372,34.268,34.268v130.525v26.611H61.223c9.307-6.99,15.339-18.116,15.339-30.625
                    c0-21.108-17.173-38.281-38.281-38.281V129.97z M15.313,256.481c0-12.664,10.304-22.968,22.969-22.968
                    c12.664,0,22.968,10.304,22.968,22.968c0,12.665-10.304,22.968-22.968,22.968C25.616,279.45,15.313,269.146,15.313,256.481z
                    M447.885,356.011H42.109c-14.776,0-26.797-12.021-26.797-26.796c0-14.775,12.021-26.796,26.797-26.796h405.775
                    c14.776,0,26.797,12.021,26.797,26.796C474.682,343.99,462.661,356.011,447.885,356.011z"
          />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </>
);

export default IconSofa;
