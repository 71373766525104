import { makeStyles } from "@material-ui/core";

export default function createStyle() {
  return makeStyles((theme) => ({
    posterContainer: {
      height: "100%",
      width: "100%",
      cursor: "pointer",
      display: "grid",
      gridTemplateAreas: "stack",
      "& > *": {
        gridColumn: "1 / -1",
        gridRow: "1 / -1"
      }
    },
    playIcon: {
      zIndex: "100",
      margin: "auto",
      width: "7rem",
      height: "7rem",
      opacity: "0.7"
    }
  }));
}
