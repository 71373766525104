import React from "react";
import GenericDialog from "../GenericDialog";
import ExpandableDescription from "../ExpandableDescription";
import { Box } from "@material-ui/core";
import ProductPrice from "../ProductPrice";
import ARViewer from "../ARViewer";
import FavoriteIcon from "../FavIcon";

const ItemDialog = ({
  open,
  setOpen,
  title,
  id,
  posterPath,
  model,
  "ios-src": iosSrc,
  backgroundColor,
  description,
  fav,
  price,
  currency,
  uid,
  photos
}) => {
  return (
    <GenericDialog title={title} open={open} setOpen={setOpen}>
      <ARViewer
        uid={uid}
        progressBarColor={"DBDBDB"}
        posterPath={posterPath}
        model={model}
        ios-src={iosSrc}
        backgroundColor={backgroundColor}
        photos={photos}
        title={title}
      />
      <Box display="flex" justifyContent="space-between">
        <FavoriteIcon favorite={fav} color="primary" />
        <ProductPrice color="primary" price={price} currency={currency} />
      </Box>
      <ExpandableDescription description={description} />
    </GenericDialog>
  );
};

export default ItemDialog;
