import { makeStyles } from "@material-ui/core";

export default function createStyle({ model }) {
  return makeStyles({
    toolBarFilters: {
      backgroundColor: model?.theme?.color3,
      height: "3%",
      minHeight: "4%",
      paddingBottom: "2%"
    },
    content: {
      backgroundColor: model?.theme?.color3,
      overflowY: "auto"
    }
  });
}
