import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Menu,
  MenuItem,
  Box,
  BottomNavigation,
  BottomNavigationAction
} from "@material-ui/core";
import CategoryIcon from "./CategoryIcon";

export default function BottomBar(props) {
  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
      backgroundColor: props?.model?.theme?.color3
    }
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      {...props}
    />
  ));

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      color: theme.palette.primary.main,
      fontFamily: "'Inter', sans-serif",
      fontSize: "0.9em",
      minHeight: 0
    }
  }))(MenuItem);

  const useStyles = makeStyles({
    root: {
      width: "100%"
    },
    submenu: {},
    bottomBar: {
      backgroundColor: props?.model?.theme?.color1
    },
    bottomAction: {
      color: "#FFFFFF"
    },
    bottomActionSelected: {
      color: "#FFFFFF"
    }
  });

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCategoryClick = (categorySelected) => {
    if (categorySelected !== props.model.app.selectedCategory) {
      props.setCategory(categorySelected);
    }
  };
  

  return (
    <Box className="bottomBar">
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.submenu}
      >
        {props?.model?.content
          ?.find(({ id }) => id === selectedCategory)
          ?.categories2
            ?.sort(({ order: orderA }, { order: orderB }) => orderA - orderB)
            ?.map(({ id, name }) => (
              <StyledMenuItem
                key={id}
                onClick={() => {
                  handleCategoryClick(id);
                  setAnchorEl(null);
                }}
              >
                {name}
              </StyledMenuItem>
            ))
        }
      </StyledMenu>

      <BottomNavigation className={classes.bottomBar} showLabels>
        {props.model.content
          ?.sort(({ order: orderA }, { order: orderB }) => orderA - orderB)
          ?.map(({ id, icon, categories2 }) => (
            <BottomNavigationAction
              key={id}
              classes={{
                iconOnly: classes.bottomActionSelected,
                selected: classes.bottomActionSelected,
                root: classes.bottomAction,
                label: classes.bottomActionSelected
              }}
              icon={<CategoryIcon category={icon} viewBox="0 0 512 512" />}
              onClick={(event) => {
                if (categories2?.length > 0) {
                  setAnchorEl(event.currentTarget);
                  setSelectedCategory(id);
                } else {
                  alert("No existen subcategorías asociadas");
                }
              }}
            />
          ))}
      </BottomNavigation>
    </Box>
  );
}
