const openModelViewer = (ref, sketchfabModel) => {
  let oModelViewer = ref.current;

  if (oModelViewer.tagName === "MODEL-VIEWER") {
    oModelViewer.activateAR();
  } else if (typeof oModelViewer === "function") {
    //window.open(`https://sketchfab.com/models/${sketchfabModel}/ar-redirect`, "toolbar=no,titlebar=no");
    oModelViewer();
  }
}

const utils = {
  openModelViewer
};

export {
  openModelViewer
}

export default utils;