import React, { useRef, forwardRef } from "react";
import ModelViewer from "../ModelViewer";
import SketchfabViewer from "../SketchtfabViewer";
import Carousel from "react-material-ui-carousel";
import createStyles from "./style";

const ARViewer = forwardRef(
  (
    {
      uid,
      progressBarColor,
      posterPath,
      model,
      iosSrc,
      backgroundColor = "DBDBDB",
      title,
      photos,
      carousel,
      showPoster,
      autoplay,
      buttonName,
      hidePlayButton,
      iconSize
    },
    ref
  ) => {
    let modelRef = useRef(null);
    const useStyles = createStyles();
    const classes = useStyles();

    const getFirstPhoto = (sPhotos) => {
      const aoPhotos = parsePhotos(sPhotos);
      return aoPhotos.length > 0 ? aoPhotos[0] : { image: "", title: "" };
    };

    const parsePhotos = (sPhotos) => {
      if (!sPhotos) {
        return [];
      }

      if (typeof sPhotos === "string") {
        return JSON.parse(sPhotos);
      }

      if (typeof sPhotos === "object") {
        return sPhotos;
      }

      return [];
    };

    return showPoster && (posterPath || photos) ? (
      <img
        className={classes.main}
        src={posterPath || getFirstPhoto(photos).image}
        alt={posterPath || getFirstPhoto(photos).title}
      />
    ) : uid ? (
      <SketchfabViewer
        photo={
          photos && photos.length ? photos[0] : undefined
        }
        showPoster={showPoster}
        autoplay={autoplay}
        iconSize={iconSize}
        title={title}
        progressBarColor={progressBarColor}
        uid={uid}
        className={classes.main}
        ref={ref || modelRef}
        hidePlayButton={hidePlayButton}
      ></SketchfabViewer>
    ) : model && !showPoster ? (
      <ModelViewer
        ref={ref || modelRef}
        className={classes.main}
        showPoster={showPoster}
        autoplay={autoplay}
        posterPath={posterPath}
        model={model}
        ios-src={iosSrc}
        backgroundColor={backgroundColor}
        buttonName={buttonName}
      />
    ) : carousel && photos.length > 1 ? (
      <Carousel animation="slide" autoPlay={false} className={classes.main}>
        {parsePhotos.map(({ image, title }) => (
          <img key={image} image={image} title={title} alt={title} />
        ))}
      </Carousel>
    ) : (
      <img
        className={classes.main}
        src={posterPath || getFirstPhoto(photos).image}
        alt={posterPath || getFirstPhoto(photos).title}
      />
    );
  }
);

export default ARViewer;
