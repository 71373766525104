import { React } from 'react';
import { Chip } from '@material-ui/core';

const FilterCard = (props) => {

    return (
        <Chip
        size="small"
        style={{fontSize: '0.7em', borderRadius: '0'}}
            {...props}
        />
    )
}

export default FilterCard;