import React, { useState } from "react";
import { Typography, Box, Link } from "@material-ui/core";
import createStyles from "./style";

const ExpandableDescription = ({ description }) => {
  const [expanded, setExpanded] = useState(false);
  const useStyles = createStyles();
  const classes = useStyles();

  const changeExpanded = () => {
    setExpanded((isExpanded) => !isExpanded);
  };

  return (
    <Box className={classes.expandableContainer}>
      <Typography variant="caption" noWrap={!expanded}>
        {description}
        {expanded && (
          <Link className={classes.expandOption} onClick={changeExpanded}>
            {"menos"}
          </Link>
        )}
      </Typography>
      {!expanded && (
        <Link
          variant="caption"
          className={classes.expandOption}
          onClick={changeExpanded}
        >
          {"más"}
        </Link>
      )}
    </Box>
  );
};

export default ExpandableDescription;
