import React from "react";

const IconMainLogo = () => (
  <>
    <path
      className="cls-1"
      d="M57.3,106.1h9.3V123H87.8V106.1h9.3v42H87.8V129.5H66.6v18.6H57.3Z"
    />
    <path className="cls-1" d="M110.1,106.1h9.3v35.7h21.8v6.3H110.1Z" />
    <path className="cls-1" d="M150,106.1h9.4v42H150Z" />
    <path
      className="cls-1"
      d="M165.3,106.1h9.9l7.4,21.1c1.7,4.8,2.8,8.9,4.6,13.7h.3c1.8-4.8,3-8.9,4.6-13.7l7.3-21.1H209l-16.3,42H181.6Z"
    />
    <path
      className="cls-1"
      d="M214.5,106.1h31.6v6.3H223.8v10.7h18.9v6.3H223.8v12.4h23.1v6.3H214.5Z"
    />
    <path
      className="cls-1"
      d="M9.8,107.9c10.5-8.7,22.7-6.6,31.3,3.8s8.4,22.8-2,31.5-23,6.8-31.6-3.6S-.7,116.6,9.8,107.9Zm23.2,28c7.3-6.1,8.3-13.8,3.1-20.1s-13-6.6-20.3-.6-8.5,14-3.3,20.3S25.7,141.9,33,135.9Z"
    />
    <path
      className="cls-1"
      d="M19.2,89.8c0-3.7,2.3-6.5,5-6.4s5,2.8,4.9,6.5-2.2,6.4-5,6.4S19.1,93.4,19.2,89.8Z"
    />
  </>
);

export default IconMainLogo;
