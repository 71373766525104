import { makeStyles } from "@material-ui/core";

export default function createStyle({ model }) {
  return makeStyles({
    appBar: {
      position: "relative",
      display: "block",
      backgroundColor: model?.theme?.color3,
      boxShadow: "none",
      border: "none"
    },
    toolbar: {
      display: "grid",
      gridTemplateAreas: "stack",
      "& > *": {
        gridColumn: "1 / -1",
        gridRow: "1 / -1"
      }
    },
    toolBarFilters: {
      backgroundColor: model?.theme?.color3,
      height: "3%",
      minHeight: "4%",
      paddingBottom: "2%"
    },
    logo: {
      display: "flex",
      justifyContent: model.app.logo ? "center" : "left",
      margin: 0,
      color: model?.theme?.color1
    },
    logo_tenant: {
      color: model?.theme?.color1
    },
    logo_tenant_img: {
      maxWidth: "7rem",
      maxHeight: "3rem"
    },
    logoIcon: {
      width: "3em",
      height: "1em",
      fontSize: "2.7rem",
      marginLeft: "2rem" //Para centrar el logo de Ohlive
    },
    favoriteContainer: {
      display: "flex",
      justifyContent: "End"
    }
  });
}
