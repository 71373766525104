import React from "react";
import { Button } from "@material-ui/core";
import { Icon360 } from "../Icons";
import createStyles from "./style";

const ARButton = ({
  sector,
  color,
  iconOnly = false,
  textOnly = false,
  subdomain,
  token,
  title,
  ...props
}) => {
  const useStyles = createStyles();
  const classes = useStyles();

  const getLinkName = () => {
    var linkName;

    switch (sector) {
      case 2: //Muebles
        linkName = "VER EN VIVO";
        break;

      case 1: //Restaurantes
      case 9999:
      default:
        linkName = "VER EN LA MESA";
        break;
    }

    return linkName;
  };

  return (
    <Button
      {...props}
      className={classes.button360}
      color="primary"
      startIcon={
        !textOnly ? (
          <Icon360
            filled={true}
            color="primary"
            data-amplify-analytics-on="click"
            data-amplify-analytics-name="click Icon 360"
            data-amplify-analytics-attrs={`subdomain:${subdomain},token:${token},product:${title}`}
            viewBox="0 0 462.4 462.4"
          />
        ) : undefined
      }
    >
      {iconOnly ? "" : getLinkName()}
    </Button>
  );
};

export default ARButton;
