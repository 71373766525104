import { makeStyles } from "@material-ui/core";

export default function createStyle() {
  return makeStyles((theme) => ({
    cardContainer: {
      position: "relative",
      display: "flex",
      gap: "0.75rem",
      bottom: "4rem",
      marginTop: "1.4rem",
      marginLeft: "1.25rem",
      width: "fit-content"
    },
    cardItem: {
      width: "32px",
      height: "32px",
      borderRadius: "50%",
      cursor: "pointer"
    },
    listContainer: {
      flexGrow: "1",
      display: "flex",
      gap: "0.75rem",
      bottom: "4rem",
      width: "fit-content"
    },
    listItem: {
      width: "32px",
      height: "32px",
      borderRadius: "50%",
      cursor: "pointer"
    },
    gridContainer: {
      flexGrow: "1",
      gap: "0.375rem",
      boxSizing: "border-box",
      paddingLeft: "0.25rem",
      paddingRight: "0.25rem",
      marginBottom: "0.5rem",
      width: "100%",
      display: "grid",
      gridTemplateColumns: "repeat(3, minmax(0, 1fr))"
    },
    gridItem: {
      maxWidth: "28px",
      aspectRatio: "1",
      borderRadius: "50%",
      cursor: "pointer"
    }
  }));
}
