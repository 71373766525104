import { connect } from 'react-redux';
import FilterList from '../components/FilterList';

const mapStateToProps = (state) => {
    return {
        model: state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        //Acciones
        setCategory: (category) => dispatch({ type: 'setSelectedCategory', text: category}),
        setFavFilter: (fav) => dispatch({ type: 'setFavFilter', text: fav})
    }
}

const createConnection = connect(
    mapStateToProps,
    mapDispatchToProps
);

const FilterListContainer = createConnection(FilterList);

export default FilterListContainer;