import React, { useRef, useState } from "react";
import {
  Card,
  CardMedia,
  IconButton,
  Typography,
  Box
} from "@material-ui/core";
import { API, graphqlOperation } from "aws-amplify";
import { listSessions, listFavs } from "../../graphql/queries";
import { deleteFavs } from "../../graphql/mutations";
import { useFavorite } from "../../hooks/useFavorite";
import Carousel from "react-material-ui-carousel";
import { useAnalytics } from "../../hooks/Analytics";
import ExpandableDescription from "../ExpandableDescription";
import createStyles from "./style";
import FavoriteIcon from "../FavIcon";
import CategoryIcon from "../CategoryIcon";
import ProductPrice from "../ProductPrice";
import ARViewer from "../ARViewer";
import { openModelViewer } from "../../helper/utils";
import ARButton from "../ARButton";
import TexturePicker from "../TexturePicker";

const ProductCard = ({ productGroup, ...props }) => {
  const { track } = useAnalytics(
    props?.model?.app?.subdomain,
    props?.model?.app?.token
  );


  const [selectedModel, setSelectedModel] = useState(productGroup?.[0] || {});

  track("event", {
    // OPTIONAL, events you want to track, by default is 'click'
    events: ["click"],
    // OPTIONAL, the prefix of the selectors, by default is 'data-amplify-analytics-'
    // in order to avoid collision with the user agent, according to https://www.w3schools.com/tags/att_global_data.asp
    // always put 'data' as the first prefix
    selectorPrefix: "data-amplify-analytics-"
  });

  const useStyles = createStyles({ model: props.model });
  const classes = useStyles();
  const [favorite, setFavorite] = React.useState(false);
  const { addFavorite } = useFavorite();
  const modelRef = useRef(null);

  const handleSetModel = (sPosterPath) => {
    setSelectedModel(
      productGroup.find(({ posterPath }) => posterPath === sPosterPath)
    );
  };

  const setFavoriteEvent = (productId) => {
    //Se obtiene el ID de la session
    API.graphql(
      graphqlOperation(listSessions, {
        filter: { token: { eq: props.model.app.token } }
      })
    )
      .then((res) => {
        var sessionId = res.data.listSessions.items[0].id;

        //Se comprueba si se está eliminando o grabando como favorito
        if (favorite) {
          //Se obtienen los favoritos para la sesión y el producto
          API.graphql(
            graphqlOperation(listFavs, {
              filter: {
                favsSessionId: { eq: sessionId },
                favsProductId: { eq: productId }
              }
            })
          )
            .then((res) => {
              var aFavs = res.data.listFavs.items;
              var aPromises = [];

              aFavs.forEach((fav) => {
                aPromises.push(
                  API.graphql(
                    graphqlOperation(deleteFavs, { input: { id: fav.id } })
                  )
                );
              });

              Promise.all(aPromises)
                .then((res) => {
                  setFavorite(!favorite);

                  //Se debe actualizar el modelo
                  props.setFav(productId, !favorite);
                })
                .catch((err) => console.error(err));
            })
            .catch((err) => console.error(err));
        } else {
          addFavorite(sessionId, productId).then((favId) => {
            //Se modifica el state del producto
            setFavorite(!favorite);

            //Se debe actualizar el modelo
            props.setFav(productId, !favorite, favId);
          });
        }
      })
      .catch((err) => console.error(err));
  };

  React.useEffect(() => {
    setFavorite(props.ownProps.fav);
  }, [props.ownProps.fav]);

  const onPressActiveAR = () => {
    openModelViewer(modelRef, props.sketchfabModel);
  };

  const getLinkName = () => {
    var linkName;

    switch (props?.model?.app?.sector) {
      case 2: //Muebles
        linkName = "VER EN VIVO";
        break;

      case 1: //Restaurantes
      case 9999:
      default:
        linkName = "VER EN LA MESA";
        break;
    }

    return linkName;
  };

  return (
    <Card className={classes.root}>
      <Box p="1rem" justifyContent="space-between" display="flex">
        <Box className={classes.cardTitle} alignItems="center" display="flex">
          <CategoryIcon
            category={props.ownProps.categoryIcon}
            color={props.model.theme.color1}
            viewBox="0 0 512 512"
          />
          <Typography variant="caption" align="left" color="primary">
            {selectedModel.name}
          </Typography>
        </Box>
        {(selectedModel.photos === null ||
          selectedModel.photos === undefined) &&
          !selectedModel.sketchfabModel && (
            <ARButton
              onClick={onPressActiveAR}
              subdomain={props?.model?.app?.subdomain}
              token={props?.model?.app?.token}
              title={selectedModel.title}
              sector={props?.model?.app?.sector}
            />
          )}
      </Box>
      {(selectedModel.sketchfabModel || selectedModel.modelPath) && (
        <CardMedia className={classes.media}>
          <Box className={classes.modelViewerContainer}>
            <ARViewer
              photos={selectedModel.photos}
              title={selectedModel.title}
              uid={selectedModel.sketchfabModel}
              buttonName={getLinkName()}
              ref={modelRef}
              progressBarColor={"DBDBDB"}
              model={selectedModel.modelPath}
              ios-src={props.ownProps["ios-src"]}
              backgroundColor={props.ownProps.backgroundColor}
            />
            <TexturePicker
              mode="C"
              productGroup={productGroup}
              handleChangePickTexture={handleSetModel}
            />
          </Box>
        </CardMedia>
      )}
      {selectedModel.photos &&
        selectedModel.photos.length > 0 &&
        !(selectedModel.sketchfabModel || selectedModel.modelPath) && (
          <Carousel
            style={{ height: "200px" }}
            animation="slide"
            autoPlay={false}
          >
            {selectedModel.photos.map((photo, index) => (
              <CardMedia
                key={index}
                className={classes.CardPhotos}
                image={photo}
              />
            ))}
          </Carousel>
        )}
      <Box display="flex" justifyContent="space-between" paddingRight="1rem">
        <IconButton
          aria-label="add to favorites"
          onClick={() => {
            setFavoriteEvent(selectedModel.modelId);
          }}
        >
          <FavoriteIcon favorite={favorite} />
        </IconButton>
        <ProductPrice
          align="right"
          className={classes.priceText}
          price={selectedModel.price}
          currency={selectedModel.currency}
        />
      </Box>
      <ExpandableDescription description={selectedModel.description} />
    </Card>
  );
};

export default ProductCard;
