import { Analytics } from "aws-amplify";

const provider = "AWSPinpoint";
const enable = true;

export const useAnalytics = (subdomain, token) => {
  return {
    track: (event, body = {}) => {
      Analytics.autoTrack(event, {
        ...body,
        // REQUIRED, turn on/off the auto tracking
        enable,
        // OPTIONAL, the default attributes of the event, you can either pass an object or a function
        // which allows you to define dynamic attributes
        attributes: { subdomain, token },
        // OPTIONAL, the service provider, by default is the Amazon Pinpoint
        provider
      });
    }
  };
};
