import { makeStyles } from "@material-ui/core";

export default function createStyle() {
  return makeStyles({
    expandableContainer: {
      display: "flex",
      padding: "1rem",
      maxWidth: "100%"
    },
    expandOption: {
      marginLeft: "0.5rem"
    }
  });
}
