import { connect } from 'react-redux';
import {MainLogo} from '../components/Icons/index';

const mapStateToProps = (state) => {
    return {
        theme: state.theme
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const createConnection = connect(
    mapStateToProps,
    mapDispatchToProps
);

const IconMainLogoContainer = createConnection(MainLogo);

export default IconMainLogoContainer;