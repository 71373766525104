export const createSession = /* GraphQL */ `
  mutation CreateSession(
    $input: CreateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    createSession(input: $input, condition: $condition) {
      favorites {
        items {
          id
          productID
          sessionID
        }
      }
      id
      ip
      token
    }
  }
`;

export const createFavs = /* GraphQL */ `
  mutation CreateFavs(
    $input: CreateFavsInput!
    $condition: ModelFavsConditionInput
  ) {
    createFavs(input: $input, condition: $condition) {
      id
      sessionID
      productID
      product {
        id
        categoryID
        tenantID
        name
        description
        modelPath
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const deleteFavs = /* GraphQL */ `
  mutation DeleteFavs(
    $input: DeleteFavsInput!
    $condition: ModelFavsConditionInput
  ) {
    deleteFavs(input: $input, condition: $condition) {
      id
      sessionID
      productID
      product {
        id
        categoryID
        tenantID
        name
        description
        modelPath
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

const mutations = {
  createSession,
  createFavs,
  deleteFavs
};

export default mutations;
