import React from "react";
import { SvgIcon } from "@material-ui/core";

const IconContainer = ({ children, color = "#ffffff", filled, ...props }) => {
  return (
    <SvgIcon
      {...props}
      style={{
        fill: filled ? color : "none",
        stroke: !filled ? color : "none",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "2%",
        color: color
      }}
    >
      {children}
    </SvgIcon>
  );
};

export default IconContainer;
