import { connect } from "react-redux";
import Home from "../components/Home/index";

const mapStateToProps = (state) => {
  return {
    model: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //Acciones
    setContent: (content) => dispatch({ type: "setContent", text: content }),
    setTheme: (theme) => dispatch({ type: "setTheme", text: theme }),
    setToken: (token) => dispatch({ type: "setToken", text: token }),
    setCategory: (category) =>
      dispatch({ type: "setSelectedCategory", text: category }),
    setFavFilter: (fav) => dispatch({ type: "setFavFilter", text: fav }),
  };
};

const createConnection = connect(mapStateToProps, mapDispatchToProps);

const HomeContainer = createConnection(Home);

export default HomeContainer;
