import { makeStyles } from "@material-ui/core";

export default function createStyle({ model }) {
  return makeStyles({
    root: {
      borderRadius: "0",
      height: "auto",
      backgroundColor: model.theme.color4,
      borderBottom: "1px solid #cecece"
    },
    media: {
      aspectRatio: "16/9",
      width: "100%",
      overflow: "hidden"
    },
    modelViewerContainer: {
      height: "100%",
      "& model-viewer": {
        height: "100%"
      }
    },
    CardPhotos: {
      height: "200px",
      with: "70%"
    },
    priceText: {
      alignSelf: "center"
    },
    cardHeaderClass: {
      "& .MuiCardHeader-action": {
        marginTop: "0"
      },
      "& .MuiCardHeader-action .MuiButtonBase-root .MuiButton-label": {
        textDecoration: "underline",
        fontSize: "0.5rem"
      },
      "& .MuiCardHeader-action .MuiButtonBase-root .MuiButton-startIcon .MuiSvgIcon-root":
        {
          fontSize: "2rem"
        }
    },
    cardTitle: {
      gap: "0.25rem",
      "& .MuiTypography-root": { textTransform: "lowercase" },
      "& .MuiTypography-root::first-letter": {
        textTransform: "capitalize"
      },
      "& .MuiSvgIcon-root": {
        fill: "none",
        stroke: "#697251"
      }
    },
    button360: {
      "& .MuiButton-label": {
        fontSize: "0.5rem",
        textDecoration: "underline"
      }
    }
  });
}
