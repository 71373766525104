import { createTheme } from "@material-ui/core/styles";

export default function createCustomTheme({ color1 = "", color2 = "" } = {}) {
  return createTheme({
    palette: {
      primary: {
        main: color1 || "#ff00ff",
        contrastText: "#ffffff"
      },
      secondary: {
        main: color2 || "#00ff00",
        contrastText: "#ffffff"
      }
    }
  });
}
