import axios from "axios";
import { createSession } from "../graphql/mutations";
import {
  callListQuery,
  callGetQuery,
  callMutation
} from "../graphql/coreService";
import jwt from "jsonwebtoken";
import { listFiles, getFile } from "../utils/loadMedia";

const setSession = (ip, dispatch) => {
  var appToken = "";

  //Se obtiene la session
  var tokenSession = localStorage.getItem("ohliveSession");

  //Se verifica si el token de session es válido y si no, se vuelve a crear uno
  try {
    appToken = tokenSession;
  } catch (err) {
    //Se genera una sesión nueva
    appToken = jwt.sign({ ip }, "secretKey", { expiresIn: "90d" });
  }

  //Informamos al modelo
  dispatch({ type: "setToken", text: appToken });

  //Creamos la session si no existe
  callListQuery("listSessions", { filter: { token: { eq: appToken } } })
    .then((sessions = []) => {
      if (sessions.length === 0) {
        callMutation("createSession", {
          input: { ip, token: appToken }
        }).then(({ id }) => {
          localStorage.setItem("sessionId", id);
        });
      } else {
        localStorage.setItem("sessionId", sessions[0].id);
      }
    })
    .catch((err) => {
      console.error(err);
      callMutation(createSession, { input: { ip, token: appToken } }).then(
        (result) => {
          console.info(result);
        }
      );
    });

  localStorage.setItem("ohliveSession", appToken);
};

const loadProductImages = async (customerId, categories1 = []) => {
  const products = categories1.flatMap(({ categories2 }) =>
    categories2.flatMap(({ products }) => products)
  ).filter(product => Boolean(product));

  return Promise.all([
    getProductImageLists(customerId, products),
    getProductModelLists(customerId, products)
  ])
    .then(([aaFiles, aaModels]) => Promise.all([getProductMediaKeys(aaFiles), getProductMediaKeys(aaModels)]))
    .then(([aImageKeys, aModelKeys]) => {
      return categories1.map(({ categories2, ...categories1 }) => ({
        ...categories1,
        categories2: categories2.map(({ products, ...categories2 }) => ({
          ...categories2,
          products: products.map((product) => ({
            ...product,
            photos: aImageKeys.filter(
              (aImage) => aImage.indexOf(product?.id) > -1
            ),
            modelPath: aModelKeys.filter(
              (sModel) => sModel.indexOf(product?.id) > -1
            )?.[0]
          }))
        }))
      }));
    })
    .catch((e) => {
      console.error(e); 
      return categories1;
    });
};

const getProductImageLists = (customerId, products) => {
  return Promise.all(
    products
      .map(({ id }) =>
        listFiles(`${customerId}/products/${id}/images`, "public")
      )
  );
};

const getProductModelLists = (customerId, products) => {
  return Promise.all(
    products.map(({ id }) =>
      listFiles(`${customerId}/products/${id}/model`, "public")
    )
  );
};

const getProductMediaKeys = (aaFiles) => {
  return Promise.all(
    aaFiles.flatMap((aFiles) => aFiles.map(({ key }) => getFile(key)))
  );
};

const loadInitalData = (dispatch) => {
  //Se recogen los datos iniciales para cargar los modelos de la app
  //Se obtiene la ip
  axios
    .get("https://api.ipify.org/?format=json")
    .then((res) => {
      setSession(res.data.ip, dispatch);
    })
    .catch((err) => {
      console.error(err);
      setSession(undefined, dispatch);
    });

  //Y por otro lado se obtienen los datos del tenant
  //get subdomain

  let host = window.location.hostname;

  if (
    host.indexOf("localhost") > -1 ||
    host.indexOf("192.168") > -1 ||
    host === "dev.d39j6f4kf4roli.amplifyapp.com"
  ) {
    //host = "b28a7b72-5204-4594-b324-a9286bedb20d.d39j6f4kf4roli.amplifyapp.com";
    host = "flowandshow.amplifyapp.com";
  }

  var sTenant = host.split(".")[0];

  //Se informa en la app el subdominio
  dispatch({ type: "setSubdomain", text: window.location.hostname });

  //Se obtiene la información del tenant
  Promise.all([
    callGetQuery("getTenant", { id: sTenant }),
    callListQuery("listCategory1Category2s", {
      filter: { tenantID: { eq: sTenant } }
    }),
    callListQuery("listCategory2Products", {
      filter: { tenantID: { eq: sTenant } }
    })
  ])
    .then(([tenant, categories1categories2, categories2products]) => {
      const categories1 = categories1categories2
        .filter(
          ({ category1ID }, index, items) =>
            items.findIndex((item) => item.category1ID === category1ID) ===
            index
        )
        .map(({ category1 }) => ({
          ...category1,
          categories2: categories1categories2
            .filter(({ category1ID }) => category1ID === category1.id)
            .map(({ category2 }) => ({
              ...category2,
              products: categories2products
                .filter(({ category2ID }) => category2ID === category2.id)
                .map(({ product }) => product)
            }))
        }));

      loadProductImages(tenant.customerID, categories1)
        .then((content) => {
          dispatch({ type: "setContent", data: content });
        })
        .catch((error) => {
          console.error(error);
          dispatch({ type: "setContent", data: [] });
        });

      dispatch({ type: "setTheme", text: tenant.theme });

      //Se informa el sector de la app
      dispatch({ type: "setSector", text: tenant.sector });

      //Se informa el logo del tenant en la app
      dispatch({ type: "setLogo", text: tenant.logo });

      tenant.customerTenantsId = tenant.customerID;
      dispatch({ type: "setTenant", text: tenant });

      //Categories1
      dispatch({ type: "setContent", data: tenant.categories1 || [] });

      //Session
      callListQuery("listSessions", {
        filter: { token: { eq: localStorage.getItem("ohliveSession") } }
      })
        .then((sessions = []) => {
          dispatch({
            type: "setFavorites",
            data:
              sessions?.[0]?.favorites?.items?.map(
                ({ productID }) => productID
              ) || []
          });

          dispatch({ type: "setLoading", text: false });
        })
        .catch((err) => {
          console.error(err);
        });
    })
    .catch((err) => {
      console.error(err);
    });
};

export default loadInitalData;
