const INITIAL_STATE = {
  app: {
    loading: true,
    token: "token",
    selectedCategory: "",
    logo: "",
    favoriteFilter: false,
    sector: 1,
    subdomain: "",
    tenant: {}
  },
  theme: {
    id: "",
    color1: "",
    color2: "",
    color3: "",
    color4: ""
  },
  favorites: [],
  content: []
};

export function generalReducer(previousState = INITIAL_STATE, action) {
  //se elimina la copia por referencia
  var oState = JSON.parse(JSON.stringify(previousState));

  switch (action.type) {
    //Acciones
    case "setContent":
      oState.content = action.data || [];
      return oState;

    case "setTheme":
      oState.theme = action.text;
      return oState;

    case "setToken":
      oState.app.token = action.text;
      return oState;

    case "setSubdomain":
      oState.app.subdomain = action.text;
      return oState;

    case "setSector":
      oState.app.sector = action.text;
      return oState;

    case "setLogo":
      oState.app.logo = action.text;
      return oState;

    case "setTenant":
      oState.app.tenant = action.text;
      return oState;

    case "setSelectedCategory":
      oState.app.selectedCategory = action.text;
      return oState;

    case "setFavFilter":
      oState.app.favoriteFilter = action.text;
      return oState;

    case "setLoading":
      oState.app.loading = action.text;
      return oState;
    case "setFavorites":
      oState.favorites = action.data;
      return oState;
    case "setFav":
      var productId = action.productId;

      oState.content.items = oState.content.items.map((category1) => {
        if (category1.products) {
          category1.products.items = category1.products.items.map((product) => {
            if (product.id === productId) {
              product.fav = action.fav;
              product.idFav = action.fav && action.favId;
            }
            return product;
          });
        } else {
          category1.categories2.items = category1.categories2.items.map(
            (category2) => {
              if (category2.products) {
                category2.products.items = category2.products.items.map(
                  (product) => {
                    if (product.id === productId) {
                      product.fav = action.fav;
                      product.idFav = action.fav && action.favId;
                    }
                    return product;
                  }
                );
              }
              return category2;
            }
          );
        }

        return category1;
      });

      return oState;

    default:
      return previousState;
  }
}
