import React, { useState } from "react";
import ProductListContainer from "../../containers/ProductListContainer";
import FilterListContainer from "../../containers/FilterListContainer";
import { Box, ButtonGroup, Button } from "@material-ui/core";
import { CropOriginal, List, Apps } from "@material-ui/icons";
import createStyles from "./styles";

const CARD_MODE = "C";
const LIST_MODE = "L";
const GRID_MODE = "G";

const AppContent = ({
  favButtonPush,
  setFavButtonPush,
  favoriteFilter,
  setFavoriteFilter,
  model
}) => {
  const useStyles = createStyles({ model });
  const classes = useStyles();
  const [mode, setMode] = useState(CARD_MODE);

  return (
    <Box flexGrow="1" className={classes.content}>
      <FilterListContainer />
      <ButtonGroup
        style={{ width: "100%" }}
        variant="outlined"
        aria-label="outlined button group"
      >
        <Button
          style={{ flexGrow: "1" }}
          onClick={() => setMode(CARD_MODE)}
          endIcon={<CropOriginal />}
        />
        <Button
          style={{ flexGrow: "1" }}
          onClick={() => setMode(LIST_MODE)}
          endIcon={<List />}
        />
        <Button
          style={{ flexGrow: "1" }}
          onClick={() => setMode(GRID_MODE)}
          endIcon={<Apps />}
        />
      </ButtonGroup>
      <ProductListContainer
        mode={mode}
        favoriteButtonPush={favButtonPush}
        setFavButtonPush={setFavButtonPush}
        favoriteFilter={favoriteFilter}
        setFavoriteFilter={setFavoriteFilter}
      />
    </Box>
  );
};

export default AppContent;
