import React, { useState } from "react";
import { PlayCircleFilled } from "@material-ui/icons";
import createStyles from "./style";
import "@sketchfab/viewer-api";

const SketchfabViewer = ({
  uid,
  title,
  hidePlayButton,
  showPoster,
  autoplay,
  progressBarColor = "DBDBDB",
  photo,
  iconSize
}) => {

  const [visibleAR, setVisibleAR] = useState(!Boolean(showPoster ?? true));
  const useStyles = createStyles();
  const classes = useStyles();

  const isAutoStart = (bAutoStart, sPhoto) => Boolean(sPhoto || bAutoStart);

  return (
    <>
      <div
        id="sketchfab"
        style={{ display: "flex", height: "100%", width: "100%" }}
      >
        {Boolean(!visibleAR && photo) ? (
          <div className={classes.posterContainer}>
            {!hidePlayButton && (
              <PlayCircleFilled
                style={{
                  width: iconSize || "7rem",
                  height: iconSize || "7rem"
                }}
                className={classes.playIcon}
                onClick={() => {
                  setVisibleAR(true);
                }}
              />
            )}
            <img
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                cursor: "pointer"
              }}
              src={photo}
              key={photo}
              image={photo}
              title={photo.title}
              alt={photo.title}
              onClick={() => {
                setVisibleAR(true);
              }}
            />
          </div>
        ) : (
          <iframe
            title={title}
            style={{ heigth: "100%", width: "100%" }}
            execution-while-out-of-viewport="true"
            execution-while-not-rendered="true"
            web-share="true"
            frameborder="0"
            allowfullscreen
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            src={`https://sketchfab.com/models/${uid}/embed?annotations_visible=0&autospin=0.1&autostart=${isAutoStart(autoplay ?? false, photo)}&camera=0&double_click=0&internal=1&max_texture_size=1024&preload=1&scrollwheel=0&sound_enable=0&transparent=1&ui_animations=0&ui_hint=0&ui_annotations=0&ui_ar=1&ui_ar_help=0&ui_color=white&ui_fadeout=0&ui_fullscreen=0&ui_help=0&ui_infos=0&ui_inspector=0&ui_settings=0&ui_stop=0&ui_theatre=0&ui_theme=dark&ui_vr=0&ui_watermark=0&ui_start=${
              hidePlayButton ? "0" : "1"
            }&ui_color=${progressBarColor}`}
          ></iframe>
        )}
      </div>
    </>
  );
};

export default SketchfabViewer;
